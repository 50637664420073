<template>
  <div class="bg-grey-6 ">
    <div class="bg-white l-mobile-container column">
      <router-view class="l-grow"> </router-view>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  setup() {
    return {};
  },
};
</script>
